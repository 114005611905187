import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { REFERRED_PROGRAM_VALUES_RE, VIRTUAL_EVENT_VALUES_RE } from '@shared/constants/recommend.constants';
import { DataLayerService } from '@shared/services/data-layer.service';
import { FinancingService } from '@shared/services/financing.service';
import { RecommendService } from '@shared/services/recommend.service';

@Component({
  selector: 'milab-box-link',
  templateUrl: './box-link.component.html',
  styleUrls: ['./box-link.component.scss']
})
export class BoxLinkComponent implements OnInit {
  @Input() type?: 'above' | 'inline' = 'inline';
  @Input() referredCode: string='991234567899';
  @Output() copied = new EventEmitter<boolean>();
  maxTitleLengthShareText: number = 55;
  inputIsDisabled: boolean = true;
  shareLink = '';
  baseUrl = '';
  selectedCopyLink: boolean = false;
  constructor(private dataLayerService: DataLayerService,private financingService: FinancingService,private recommendService: RecommendService) { }

  ngOnInit(): void {
    this.shareLinkOnInit(window.location.origin);
    console.log(this.referredCode);
    
  }

  copyLink(type: string) {
    navigator.clipboard
    .writeText(this.shareLink)
    .then(() => {
      this.selectedCopyLink = true;
      this.copied.emit(true);
      this.dataLayerPush(type);
      if (this.type === 'inline') {
        setTimeout(() => {
          this.selectedCopyLink = false;
          this.copied.emit(false);
        }, 2000);
      }
    })
    .catch((err) => { });
  }

  shareLinkOnInit(baseUrl: string): void {
    const data = this.financingService.utmsRecommended;
    if (data.utmSource || data.utmMedium || data.utmCampaign || data.utmContent ) {
      this.shareLink = `${baseUrl}${REFERRED_PROGRAM_VALUES_RE.registerRoute}${this.referredCode}&utm_source=${data.utmSource}&utm_medium=${data.utmMedium}&utm_campaign=${data.utmCampaign}&utm_content=${data.utmContent}`;
    } else {
      this.shareLink = `${baseUrl}${REFERRED_PROGRAM_VALUES_RE.registerRoute}${this.referredCode}`;
    }
  }

  truncateTextRecommend(text: string, maxLength: number): string {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  }
  
  handleKeyDown(event: KeyboardEvent): void {
    //Do something
  }
  
  dataLayerPush(type: string): void {
    this.dataLayerService.virtualEventEnlaceRefer(type);
  }

  updateInterest(solicitorCode: string, isInterested: boolean) {
    this.recommendService.updateInterest(solicitorCode, isInterested)
      .subscribe({
        next: () => console.log('Interés actualizado exitosamente'),
        error: (err: any) => console.error('Error al actualizar el interés', err)
      });
  }
}
