<div *ngIf="selectedAlly">
  <div class="row breadcrumb breadcrumb-desktop d-none d-lg-block">
    <div>
      <yevo-breadcrumb [breadcrumbs]="breadcrumbs" class="col-12"></yevo-breadcrumb>
    </div>
  </div>
  <div class="d-block d-lg-none">
    <div class="p-0">
      <img
        class="img-fluid w-100"
        [src]="selectedAlly.img"
        [alt]="selectedAlly.alt"
        style="background-color: green;"
        *ngIf="ally != 'aprobadodigital' && ally !== 'miprimercredito' && ally !== 'preaprobadodigital' && ally !== 'compradeudaexpress' && ally !== ''"
      />
      <img
        class="img-fluid w-100"
        [src]="selectedAlly.imgMobile"
        [alt]="selectedAlly.alt"
        style="background-color: red;"
        *ngIf="ally == 'aprobadodigital' || ally == 'miprimercredito' || ally == 'preaprobadodigital' || ally == 'compradeudaexpress' || ally == ''"
      />
    </div>
  </div>
  <div class="d-none d-lg-block container-img-desktop" [ngClass]="{ 'mt-3-desktop': !isAmountFormVisible }">
    <img
      class="img-fluid img-desktop"
      [ngClass]="{ 'banner-amount': isAmountFormVisible }"
      [src]="selectedAlly.img"
      [alt]="selectedAlly.alt"
    />
  </div>
  <div class="row breadcrumb breadcrumb-mobile d-block d-lg-none">
    <yevo-breadcrumb [breadcrumbs]="breadcrumbs" class="col-12"></yevo-breadcrumb>
  </div>
</div>
