<div class="share-link-box mt-2" *ngIf="type === 'inline'">
    <div class="share-link-box__input-group-content">
      <input type="text" 
      [readonly]="inputIsDisabled"
      class="share-link-box__input" 
      value="{{ truncateTextRecommend(shareLink , maxTitleLengthShareText) }}" 
      aria-describedby="basic-addon2">
      <div class="share-link-box__button--mobile" id="basic-addon2" (click)="copyLink('final')" (keydown)="handleKeyDown($event)">
          <img src="/assets/icons/refers/copy.svg" alt="copiar enlace" class="share-link-box__icon"> 
          <span class="share-link-box__label">copiar</span> 
      </div>
    </div>
</div>
<div class="share-link-box mt-2" *ngIf="type === 'above'">
    <div class="share-link-box__content-copy">
      <input type="text" 
      disabled
      class="share-link-box__input-full" 
      value="{{ truncateTextRecommend(shareLink , maxTitleLengthShareText) }}" 
      aria-describedby="basic-addon2">
      <span class="btn btn-primary btn-green btn-round" id="basic-addon2" (click)="copyLink('thanks')" (keydown)="handleKeyDown($event)">
          <img *ngIf="selectedCopyLink" src="/assets/icons/refers/ico_white_check.svg" alt="enlace copiado" class="mr-2"> 
          <span class="">{{selectedCopyLink ? '¡Enlace copiado!' : 'Copiar enlace'}}</span>
      </span>
    </div>
</div>